
    document.addEventListener('DOMContentLoaded', function () {

        AOS.init({
            disable: false, // отключает анимацию на указанных устройствах
            startEvent: 'DOMContentLoaded', // имя события, которое AOS должен запускать после загрузки контента
            initClassName: 'aos-init', // класс, добавляемый после инициализации
            animatedClassName: 'aos-animate', // класс, добавляемый при анимации
            useClassNames: false, // если true, будет добавлять содержимое data-aos в виде классов
            disableMutationObserver: false, // отключает автоматическое обнаружение изменений (advanced)
            debounceDelay: 50, // задержка на debounce при изменении размера окна (advanced)
            throttleDelay: 99, // задержка на throttle при прокрутке страницы (advanced)
            
            // настройки, которые могут быть переопределены на уровне каждого элемента, с помощью атрибутов data-aos-*
            offset: 120, // отступ (в px) от оригинального триггера
            delay: 0, // значения от 0 до 3000, с шагом 50мс
            duration: 400, // значения от 0 до 3000, с шагом 50мс
            easing: 'ease-in-out', // тип ускорения по умолчанию
            once: true, // анимация должна случаться только один раз - при прокрутке вниз
            mirror: false, // анимация должна быть зеркальной при прокрутке назад
            anchorPlacement: 'top-bottom', // определяет положение элемента относительно окна
        });


        // Получаем все контейнеры с iframe
        const frameContainers = document.querySelectorAll('.frame-container');

        frameContainers.forEach((container) => {
            const iframe = container.querySelector('.youtube-frame');
            const loader = container.querySelector('.frame-loader');

            // Событие, которое срабатывает при загрузке каждого iframe
            iframe.addEventListener('load', function () {
                loader.style.display = 'none'; // Скрываем лоадер после загрузки
                iframe.style.display = 'block'; // Показываем iframe
                // Добавляем плавное появление
                setTimeout(() => {
                    iframe.style.opacity = 1; // Устанавливаем непрозрачность для плавного появления
                }, 50); // Небольшая задержка для правильного срабатывания transition
            });
        });


        var swiper = new Swiper('.swiper-container', {
            slidesPerView: 1,       // По умолчанию показываем 1 слайд на десктопе
            spaceBetween: 30,       // Расстояние между слайдами
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            // Адаптивные настройки
            breakpoints: {
              660: {
                slidesPerView: 2,    // Показываем 1 слайд на десктопах
                spaceBetween: 30     // Больше отступов
              },
              920: {
                slidesPerView: 3,    // Показываем 1 слайд на десктопах
                spaceBetween: 30     // Больше отступов
              }
            }
          });





              // Функция для генерации случайного пароля
    function generatePassword(length) {
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+<>?";
        var password = "";
        for (var i = 0; i < length; i++) {
            password += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return password;
    }

    // Обработчик нажатия на кнопку "Сгенерировать пароль"
    var generateBtn = document.getElementById('generate-password-btn');
    if (generateBtn) {
        generateBtn.addEventListener('click', function () {

            var passwordFields = document.querySelectorAll('input[type="password"]'); // Все поля пароля в форме
            for (var i = 0; i < passwordFields.length; i++) {
                var passwordField = passwordFields[i];
                var newPassword = generatePassword(12); // Генерируем пароль длиной 12 символов
                passwordField.value = newPassword; // Устанавливаем сгенерированный пароль в поле
                if(i == 0){
                  alert('Your password is: ' + newPassword); // Показываем уведомление с паролем
                }
            }


        });
    }





    });
